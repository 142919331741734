<template>
  <v-app>
    <v-container>
      <v-subheader class="mb-0 py-0" height="20">
        <h2>{{ student.empname }}</h2>
      </v-subheader>
      <v-subheader>
        <h3>{{ student.year + "-" + (parseInt(student.year) + 1) }}学年第学生评价</h3>
      </v-subheader>
      <v-card v-for="(ds, k) in data" :key="k" class="mb-3" elevation="1" tile>
        <v-card-title>
          {{ ds.name }}
        </v-card-title>
        <v-card-text>
          <span v-html="ds.html"></span>
        </v-card-text>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      student: {
        loginid: "chenmingge_103s",
        empname: "陈茗格",
        schoolcode: "103",
        mobile: "13798287515",
        sfz: "440303201108044821",
        sex: true,
        identity: 0,
        year: 2019,
      },
      data: {},
    };
  },
  created() {},
  mounted() {
    this.student = JSON.parse(this.$route.query.student);
    this.student.year = this.$route.query.year;
    this.load();
  },
  methods: {
    
    load() {
      let data = {
        module: "student_mark",
        title: "get_open2",
        data: {
          name: this.student.empname,
          idcard: this.student.sfz,
          schoolid: "440201000103",
          year: this.student.year,
        },
      };
      //let that = this;
      this.$sopen.requestApi(data).then((res) => {
        console.log(res);
        let mark = res.student_mark;
        let data = [];
        for (let x in mark) {
          let dm = {};
          let ds = mark[x];
          let html = "<table class='istable'><tbody>";
          for (let y in ds.data) {
            let s = y == 1 ? "上" : "下";
            html += "<tr>";
            html += "<td rowspan='2'>" + s + "学期</td>";
            dm[s] = {};
            for (let d in ds.data[y]) {
              html += "<td";
              if (ds.data[y][d]["data"] && ds.data[y][d]["data"].length > 1) {
                html += " colspan='" + ds.data[y][d]["data"].length + "'";
              }
              html += " style='text-align:center'>" + ds.data[y][d].name + "</td>";
              dm[s][d] = {
                colspan: ds.data[y][d]["data"] ? ds.data[y][d]["data"].length : 0,
                data: ds.data[y][d]["data"],
                name: ds.data[y][d].name,
              };
            }
            html += "</tr>";
            html += "<tr>";
            dm[s] = {};
            for (let d in ds.data[y]) {
              for (let x in ds.data[y][d]["data"]) {
                let n = ds.data[y][d]["data"][x];
                html += "<td>" + n.val + "</td>";
              }
            }
            html += "</tr>";
          }
          html += "</tbody></table>";
          data.push({
            id: x,
            name: ds.name,
            //data: dm,
            html: html,
          });
        }
        console.log(data);
        this.data = data;
      });
    },
  },
};
</script>

<style></style>
